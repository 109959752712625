import * as React from 'react'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@biogroup/utils/cn'

const tooltipVariants = cva(
  cn(
    'group z-50 overflow-hidden rounded-md border bg-background p-2',
    'text-sm text-primary-foreground shadow-md animate-in fade-in-0 zoom-in-95',
    'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
    'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2',
    'data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
  ),
  {
    variants: {
      variant: {
        sm: 'is-sm-variant',
        md: 'is-md-variant',
      },
    },
    defaultVariants: {
      variant: 'sm',
    },
  },
)

export type TooltipVariantProps = VariantProps<typeof tooltipVariants>

const TooltipProvider = TooltipPrimitive.Provider

const TooltipContainer = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> &
    TooltipVariantProps
>(({ variant, className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(tooltipVariants({ variant }), className)}
    {...props}
  />
))

TooltipContent.displayName = TooltipPrimitive.Content.displayName

function TooltipLabel({ children }: { children: React.ReactNode }) {
  return (
    <h3 className="text-sm font-semibold text-foreground group-[.is-md-variant]:text-md">
      {children}
    </h3>
  )
}

function TooltipDescription({ children }: { children: React.ReactNode }) {
  return (
    <p className="text-xs font-normal text-muted-foreground group-[.is-md-variant]:text-sm">
      {children}
    </p>
  )
}

function Tooltip({
  trigger,
  children,
  className,
  open: openProp,
}: {
  trigger?: string | React.ReactNode
  children: React.ReactNode
  className?: string
  open?: boolean
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <TooltipContainer open={openProp !== undefined ? openProp : open}>
      <TooltipTrigger asChild>
        <button
          type="button"
          aria-label="toolTip"
          className={cn('cursor-help text-right', className)}
          onClick={() => setOpen(!open)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          onTouchStart={() => setOpen(!open)}
          onKeyDown={e => {
            e.preventDefault()
            e.key === 'Enter' && setOpen(!open)
          }}
        >
          {trigger}
        </button>
      </TooltipTrigger>
      <TooltipContent>
        <span className="inline-block">{children}</span>
      </TooltipContent>
    </TooltipContainer>
  )
}

export {
  Tooltip,
  TooltipContainer,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipLabel,
  TooltipDescription,
}
